export function FormatPhone(phone) {
    if (!phone) {
        return "";
    }
    if (Number.isInteger(phone)) {
        phone = phone.toString();
    }
    if (phone.startsWith("+354")) {
        phone = phone.substring(4);
    }
    var phoneTrim = phone.trim();
    if (phoneTrim.length !== 7) {
        return phone;
    }
    return phoneTrim.slice(0, 3) + " " + phoneTrim.slice(3, 7);
}

export function FormatWebsite(url) {
    if (!url) {
        return "";
    }

    if (url.startsWith("http://")) {
        url = url.substring(7);
    } else if (url.startsWith("https://")) {
        url = url.substring(8);
    }

    if (url.startsWith("www.")) {
        url = url.substring(4);
    }
    if (url.endsWith("/")) {
        url = url.substring(0, url.length - 1);
    }
    return url;
    //return phoneTrim.slice(0,3) + " " + phoneTrim.slice(3,7)
}

export function FormatKennitala(kennitala) {
    if (!kennitala) {
        return "";
    }
    var kennitalaTrim = kennitala.trim();
    if (kennitalaTrim.length !== 10) {
        return kennitala;
    }
    return kennitalaTrim.slice(0, 6) + "-" + kennitalaTrim.slice(6, 10);
}

export function FormatAddress(street, house_number, zip, city) {
    let address = "";
    if (street) {
        address = street?.trim?.();
    }
    if (house_number) {
        address += " " + house_number?.trim?.();
    }
    if (street || house_number) {
        address += ", ";
    }
    if (zip && zip >= 100) {
        address += zip;
    }
    if (city) {
        address += " " + city;
    }
    return address;
}
